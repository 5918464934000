import {Grid, Spacer, Text, useMediaQuery, useToasts} from "@geist-ui/core"
import { useState } from "react"
import { useRouter } from "next/router"

import { Card, Input, Button } from "../client/ui"
import { WordMarkLogo } from '../client/components/logo'
import { login } from "../client/auth"

const LoginPage = ({ callback }) => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const router = useRouter()
    const { query: { redirect, code } } = router
    const {setToast} = useToasts()
    const alertError = text => setToast({ text, type: 'error' })

    const submit = e => {
        e.preventDefault()
        setLoading(true)

        login(email, password, code)
            .then((user) => {
                if (!callback) router.push(redirect ?? '/')
                else callback(user)
            })
            .catch((error) => {
                const errorMessage = error.message;
                alertError(errorMessage)
                setLoading(false)
                // ...
            });
    }


    return <Card style={{ maxWidth: "450px", margin: "auto", padding: "50px", marginTop: "100px" }}>
        <form onSubmit={submit}>
            <Grid.Container direction="column" alignContent="center" justify="center" alignItems="center">
                <Spacer h={4} />
                <Grid>
                    <WordMarkLogo />
                </Grid>
                <Grid>
                </Grid>
                <Text p style={{ "fontWeight": 500, opacity: 0.7, width: "227px", textAlign: "center" }}>Welcome back. Log in with your email below.</Text>
                <Spacer h={2} />
                <Input onChange={e => { setEmail(e.target.value) }} placeholder="email@domain.co.za" value={email} />
                <Spacer h={0.5} />
                <Input onChange={e => { setPassword(e.target.value) }} placeholder='password' type='password' value={password} />
                <Spacer h={2} />
                <input type='submit' style={{ opacity: 0 }} />
                <div>
                    <Button loading={loading} onClick={submit}>Log In 	&#8594;</Button>
                </div>
            </Grid.Container> </form>
    </Card>
}

export default LoginPage